<template>
  <v-snackbar
    v-model="$store.state.snackbar"
    @input="$emit('input', $event)"
    :timeout="timeout"
  >
    {{ $store.state.snackbar_text }}
    <slot />

    <template v-slot:action="{ attrs }">
      <BlueButton text :attrs="attrs" @click="$store.commit('setSnackBar')">
        Close
      </BlueButton>
    </template>
  </v-snackbar>
</template>

<script>
import BlueButton from "@/components/atoms/VButton/BlueButton";
export default {
  data() {
    return {
      timeout: 1500,
    };
  },
  components: {
    BlueButton,
  },
};
</script>
