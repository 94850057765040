<template>
  <v-btn
    color="blue"
    @click="$emit('click')"
    v-bind="attrs"
    :text="text"
    :disabled="disabled"
  >
    <slot />
  </v-btn>
</template>
<script>
export default {
  props: ["disabled", "text", "attrs"],
};
</script>
