// atoms
import MultipleSelect from "@/components/atoms/VSelect/MultipleSelect";
import PaddingGrayButton from "@/components/atoms/VButton/PaddingGrayButton";
// organisms
import SnackBar from "@/components/organisms/SnackBar/SnackBar";
import LoadingSpinner from "@/components/organisms/LoadingSpinner/LoadingSpinner";

import EditDialog from "@/components/organisms/VDialog/EditDialog";
import header from "@/assets/table/purchase/purchaseCalculateHeaders";
import PurchaseMainTable from "@/components/organisms/FilterList/purCaculReleasList.vue";
import PrintPurchaseCalcTable from "./PrintPurchaseCalcTable";
import numFilterDialog from "@/components/organisms/VDialog/numFilterDialog";

import { mapGetters } from "vuex";

export default {
  components: {
    LoadingSpinner,
    MultipleSelect,
    PaddingGrayButton,
    PurchaseMainTable,
    SnackBar,
    PrintPurchaseCalcTable,
    numFilterDialog,
    // ReleaseChangeForm,
    EditDialog,
  },

  data() {
    return {
      header: header.filter((data) => {
        return this.$store.state.auth_grade < 2
          ? data.value !== "check_status"
          : data;
      }),
      totalArea: [
        "checkBox",
        "kind",
        "year",
        "month",
        "season",
        "order",
        "buyer",
        "item",
        "placeForward",
        "color",
        "salesMonth",
      ],
    };
  },

  computed: {
    ...mapGetters({
      approvals: "getApprovalList",
      defaultReleaseList: "getDefaultReleaseList",
      modifyRelease: "getChangeData",
      dialog: "getChangeDialog",
      releaseList: "getPurchCalculList",
      calculateTotal: "getCalculateTotal",
    }),

    selected: {
      get() {
        return this.$store.state.selected;
      },
      set(data) {
        this.$store.state.selected = data;
      },
    },

    purchaseUnitPrice() {
      return this.calculateTotal.totalAmount &&
        this.calculateTotal.purchaseQuantity
        ? parseFloat(
            (
              this.calculateTotal.totalAmount /
              this.calculateTotal.purchaseQuantity
            ).toFixed(2),
          )
        : 0;
    },
    heightLeftDatatable() {
      return `${this.windowHeight - 200}px`;
    },
  },
  methods: {
    confirmDialogOpen(kind) {
      this.setCheckSelected();
      if (kind === "carry" && !this.checkSelected()) {
        this.$store.commit("setSnackBar", `이월 할 건을 선택해주세요.`);
        return;
      }
      if (kind === "end" && !this.checkSelected()) {
        this.$store.commit("setSnackBar", `마감 할 건을 선택해주세요.`);
        return;
      }
      if (kind === "reject" && !this.checkSelected()) {
        this.$store.commit("setSnackBar", `반려 할 건을 선택해주세요.`);
        return;
      }
      if (
        confirm(
          `${this.$store.state.selected.length} 건을 ${
            kind === "carry"
              ? "이월"
              : kind === "end"
              ? `${this.$store.state.auth_grade >= 2 ? "마감" : "마감 요청"}`
              : "반려"
          } 하시겠습니까?`,
        )
      ) {
        this.$store.dispatch("TASK_SELECT_PURCHASECAC_SAVE", kind);
      }
    },
    checkSelected() {
      return this.$store.state.selected.length ? true : false;
    },

    setCheckSelected() {
      const selected = [];
      this.releaseList.forEach((x) => {
        if (x.checkBox) {
          selected.push(x);
        }
      });
      this.$store.commit("setSelected", selected);
    },
  },

  created() {
    this.$store.commit("setSelected");
    this.$store.dispatch("GET_SELECT_CLIENT_LIST");
    this.$store.dispatch("SET_API_LOADING", false);
  },
  mounted() {},
  // sockets: {
  //   async purchase(data) {
  //     if (this.$store.state.auth_company === data.company)
  //       await this.$store.dispatch("GET_RELEASELIST", {
  //         kind: "purchasecalculate",
  //       });
  //   },
  // },
};
