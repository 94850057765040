<template>
  <div
    class="textEllipsis text-center align-center"
    :class="Tooltip < 0 ? 'minusPrice' : ''"
  >
    <v-tooltip max-width="300px" color="gray" left>
      <template v-slot:activator="{ on, attrs }">
        <span
          v-on="on"
          v-bind="attrs"
          style="
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: calc(100vw - 91vw);
          "
        >
          {{ Tooltip }}

          <slot />
        </span>
      </template>
      <span> {{ Tooltip }}</span>
    </v-tooltip>
  </div>
</template>
<script>
export default {
  props: {
    Tooltip: String,
  },
};
</script>
