<template>
  <v-dialog
    :value="num_dialog"
    @click:outside="$store.state.isDialog = false"
    @keydown.esc="$store.state.isDialog = false"
    @keydown.enter="numFilter"
    max-width="600px"
  >
    <v-card>
      <v-card-title>
        <span class="headline">사용자 지정 필터</span>
      </v-card-title>

      <v-card-text>
        <span
          >다음 조건에 맞는 항목 표시 :
          {{ numDialogData.headerText }}
        </span>
        <v-container>
          <v-row>
            <template v-if="numDialogData.type !== 'area'">
              <v-col cols="6">
                <v-text-field
                  dense
                  outlined
                  disabled
                  :value="
                    numDialogData.type === 'over' ? '보다 큼' : '보다 작음'
                  "
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  dense
                  type="number"
                  outlined
                  v-model="numValue"
                ></v-text-field>
              </v-col>
            </template>
            <template v-else>
              <v-col cols="5">
                <v-text-field
                  dense
                  outlined
                  disabled
                  value="해당 범위"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  type="number"
                  dense
                  outlined
                  v-model="range1"
                ></v-text-field>
              </v-col>
              <v-col cols="1" class="mt-2 pr-0">
                <span> 에서 </span>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  type="number"
                  dense
                  outlined
                  v-model="range2"
                ></v-text-field>
              </v-col>
            </template>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="$store.state.isDialog = false"
        >
          취소
        </v-btn>
        <v-btn color="blue darken-1" text type="number" @click="numFilter">
          확인
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      numValue: "",
      range1: "",
      range2: "",
    };
  },
  computed: {
    num_dialog() {
      return this.$store.state.isDialog === true &&
        this.$store.state.isKind === "num_dialog"
        ? true
        : false;
    },
    ...mapGetters({
      orderList: "getNumFilterList",
      numDialogData: "getNumDialogData",
    }),
  },
  methods: {
    numFilter() {
      if (this.valueCheck(this.numDialogData.type)) return;
      const params = this.setParams();
      const pageParams = this.setPageParams(params);

      this.numDialogData.numFiltering(this.numDialogData.field, pageParams);
      this.init();
    },
    valueCheck(type) {
      return (
        (this.numValue === "" && (type === "over" || type === "low")) ||
        ((this.range1 === "" || this.range2 === "") && type === "area")
      );
    },

    setPageParams(params) {
      const pageParams = this.copyData(this.numDialogData.params);
      const field = this.numDialogData.field;
      const index = pageParams.findIndex((x) => x.field === field);

      if (index < 0) {
        pageParams.push({
          field,
          params,
        });
      } else {
        pageParams[index] = {
          field,
          // params: [...new Set([...pageParams[index].params, ...params])],
          params: params,
        };
      }
      return pageParams;
    },

    setParams() {
      return this.numDialogData.type === "over"
        ? this.overParmas()
        : this.numDialogData.type === "low"
        ? this.lowParams()
        : this.areaParams();
    },

    overParmas() {
      return this.numDialogData.filters
        .filter((x) => +this.numValue < +x.name)
        .map((x) => x.name);
    },
    lowParams() {
      return this.numDialogData.filters
        .filter((x) => +x.name < +this.numValue)
        .map((x) => x.name);
    },
    areaParams() {
      return this.numDialogData.filters
        .filter((x) => +this.range1 < +x.name && +x.name <= +this.range2)
        .map((x) => x.name);
    },

    init() {
      this.numValue = "";
      this.range1 = "";
      this.range2 = "";
      this.$store.commit("setDailogStatus", {
        status: false,
        kind: "num_dialog",
      });
    },
  },
};
</script>
