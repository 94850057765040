<template>
  <div class="overlay" v-if="$store.state.isAPILoading">
    <ProgressCircular />
  </div>
</template>

<script>
import ProgressCircular from "@/components/atoms/VProgressCircular/ProgressCircular";
export default {
  components: {
    ProgressCircular,
  },
};
</script>
