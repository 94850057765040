<template>
  <v-select
    multiple
    class="pt-0 mt-0 pb-0 mb-0"
    :disabled="disabled"
    :label="label"
    :item-text="ItemText"
    :items="items"
    :value="value"
    @input="$emit('input', $event)"
    @change="$emit('change')"
  >
    <template v-slot:selection="{ item, index }">
      <span v-if="value.length < 2"
        >{{ item.name ? item.name : item.label }}
      </span>
      <span v-if="value.length > 1">
        <span v-if="index === 1" class="text-no-wrap">
          {{ item.name ? item.name : item.label }} (+{{ value.length - 1 }})
        </span>
      </span>
    </template>
  </v-select>
</template>

<script>
export default {
  props: ["value", "label", "items", "disabled", "ItemText"],
};
</script>
