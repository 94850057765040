<template>
  <v-btn
    @click="$emit('click')"
    block
    large
    class="elevation-0 my-3 font-weight-black"
    color="grey lighten-2 "
    :disabled="disabled"
  >
    <slot />
  </v-btn>
</template>
<script>
export default {
  props: ["disabled"],
};
</script>
