var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":_vm.dialog_edit,"max-width":"600px"},on:{"click:outside":function($event){_vm.$store.state.isDialog = false},"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }_vm.$store.state.isDialog = false},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.saveEditForm.apply(null, arguments)}]}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.typeTitle[_vm.$store.state.dialogType]))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.$store.state.dialogType === 'purchasePlace')?[_c('v-autocomplete',{attrs:{"items":_vm.$store.state.clientList,"return-object":"","item-text":"name","label":"거래처"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" "+_vm._s(item.bill_client ? (" - " + (item.bill_client.name)) : "")+" ")]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}}],null,false,3416885170),model:{value:(_vm.$store.state.form.place_forward),callback:function ($$v) {_vm.$set(_vm.$store.state.form, "place_forward", $$v)},expression:"$store.state.form.place_forward"}})]:_vm._e(),(_vm.$store.state.dialogType === 'purchaseQuantity')?[_c('v-text-field',{attrs:{"autofocus":"","type":"number","label":"매입 수량"},model:{value:(_vm.$store.state.form.quantity_truth),callback:function ($$v) {_vm.$set(_vm.$store.state.form, "quantity_truth", $$v)},expression:"$store.state.form.quantity_truth"}})]:_vm._e(),(_vm.$store.state.dialogType === 'eschamge')?[_c('v-text-field',{attrs:{"autofocus":"","type":"number","label":"매입 환율"},model:{value:(_vm.$store.state.form.eschamge),callback:function ($$v) {_vm.$set(_vm.$store.state.form, "eschamge", $$v)},expression:"$store.state.form.eschamge"}})]:_vm._e(),(
                _vm.$store.state.dialogType === 'memo' ||
                _vm.$store.state.dialogType === 'salesMemo'
              )?[_c('v-textarea',{attrs:{"autofocus":"","rows":"5","label":"메 모"},model:{value:(_vm.$store.state.form.memo),callback:function ($$v) {_vm.$set(_vm.$store.state.form, "memo", $$v)},expression:"$store.state.form.memo"}})]:_vm._e(),(_vm.$store.state.dialogType === 'salesPlace')?[_c('v-autocomplete',{attrs:{"items":_vm.$store.state.clientList,"return-object":"","item-text":"name","label":"매출처"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" "+_vm._s(item.bill_client ? (" - " + (item.bill_client.name)) : "")+" ")]}},{key:"selection",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}}],null,false,3416885170),model:{value:(_vm.$store.state.form.place_sales),callback:function ($$v) {_vm.$set(_vm.$store.state.form, "place_sales", $$v)},expression:"$store.state.form.place_sales"}})]:_vm._e(),(_vm.$store.state.dialogType === 'salesQuantity')?[_c('v-text-field',{attrs:{"autofocus":"","type":"number","label":"매출 수량"},model:{value:(_vm.$store.state.form.sales_total_quantity),callback:function ($$v) {_vm.$set(_vm.$store.state.form, "sales_total_quantity", $$v)},expression:"$store.state.form.sales_total_quantity"}})]:_vm._e(),(_vm.$store.state.dialogType === 'saleEschamge')?[_c('v-text-field',{attrs:{"autofocus":"","type":"number","label":"매출 수량"},model:{value:(_vm.$store.state.form.eschamge),callback:function ($$v) {_vm.$set(_vm.$store.state.form, "eschamge", $$v)},expression:"$store.state.form.eschamge"}})]:_vm._e(),(_vm.$store.state.dialogType === 'salesUnitPrice')?[_c('v-text-field',{attrs:{"autofocus":"","label":"단가","prefix":_vm.$store.state.form.currency_sign},model:{value:(_vm.$store.state.form.unit_price_of_sales),callback:function ($$v) {_vm.$set(_vm.$store.state.form, "unit_price_of_sales", $$v)},expression:"$store.state.form.unit_price_of_sales"}})]:_vm._e()],2)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.$store.state.isDialog = false}}},[_vm._v(" 취소 ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","type":"number"},on:{"click":_vm.saveEditForm}},[_vm._v(" 변경 ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }