var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',[_c('LoadingSpinner'),_c('div',{staticClass:"purchaseArea"},[_c('div',{staticClass:"tableContainer"},[_c('PurchaseMainTable')],1),_c('div'),_c('div',{staticClass:"totalContainer"},[_c('div',{staticClass:"totalBorder"},[_c('div',{staticClass:"totalTop",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"tableRowCell",staticStyle:{"width":"100%"}},_vm._l((_vm.header),function(col){return _c('div',{key:col.text,staticClass:"tableHaeder text-center",class:{
                areaHead: _vm.totalArea.includes(col.value),
                areaBodyLeft:
                  col.value !== 'salesQuantity' &&
                  !_vm.totalArea.includes(col.value),
                areaBodyLeftFirst: col.value === 'salesQuantity',
              },style:({ width: col.width })},[(col.value === 'item')?_c('div',[_c('span',{staticClass:"TotalBody"},[_vm._v("TOTAL")])]):_vm._e(),(col.value === 'salesQuantity')?_c('span',[_vm._v(" 출고 ")]):_vm._e(),(col.value === 'totalQuantity')?_c('span',[_vm._v(" 출고 수량 합계 ")]):_vm._e(),(col.value === 'purchaseQuantity')?_c('span',[_vm._v(" 매입 수량 합계 ")]):_vm._e(),(col.value === 'purchaseUnitPrice')?_c('span',[_vm._v(" 평균 단가 ")]):_vm._e(),(col.value === 'totalAmount')?_c('span',[_vm._v(" 정산 금액 합계 ")]):_vm._e()])}),0)]),_c('div',{staticClass:"flexTableBody totalBottom"},[_c('div',{staticClass:"tableRowCell",staticStyle:{"width":"100%"}},_vm._l((_vm.header),function(col){return _c('div',{key:col.text,staticClass:"text-center pl-1",class:{
                areaBody: _vm.totalArea.includes(col.value),
                tableCell:
                  col.value !== 'salesQuantity' &&
                  !_vm.totalArea.includes(col.value),
                areaBodyLeftFirstBody: col.value === 'salesQuantity',
              },style:({ width: col.width })},[(col.value === 'salesQuantity')?_c('span',{staticClass:"text-bold"},[_vm._v(" "+_vm._s(_vm.calculateTotal.totalCount ? _vm.calculateTotal.totalCount : 0)+" 건 ")]):_vm._e(),(col.value === 'totalQuantity')?_c('span',{staticClass:"text-bold"},[_vm._v(" "+_vm._s(_vm._f("makeComma")(((_vm.calculateTotal.totalQuantity ? _vm.calculateTotal.totalQuantity : 0) + "y")))+" ")]):_vm._e(),(col.value === 'purchaseQuantity')?_c('span',{staticClass:"text-bold"},[_vm._v(" "+_vm._s(_vm._f("makeComma")(((_vm.calculateTotal.purchaseQuantity ? _vm.calculateTotal.purchaseQuantity : 0) + "y")))+" ")]):_vm._e(),(col.value === 'purchaseUnitPrice')?_c('span',{staticClass:"text-bold"},[_c('div',{staticClass:"d-flex"},[_c('div',{},[_vm._v("₩")]),_c('div',{staticClass:"claimPrice",class:_vm.purchaseUnitPrice < 0 ? 'minusPrice' : ''},[_vm._v(" "+_vm._s(_vm._f("makeComma")(_vm.purchaseUnitPrice))+" ")])])]):_vm._e(),(col.value === 'totalAmount')?_c('span',{staticClass:"text-bold"},[_c('div',{staticClass:"d-flex"},[_c('div',{},[_vm._v("₩")]),_c('div',{staticClass:"claimPrice",class:_vm.calculateTotal.totalAmount < 0 ? 'minusPrice' : ''},[_vm._v(" "+_vm._s(_vm._f("makeComma")(_vm.calculateTotal.totalAmount ? _vm.calculateTotal.totalAmount : 0))+" ")])])]):_vm._e()])}),0)])])])]),_c('EditDialog'),_c('SnackBar'),_c('numFilterDialog')],1)}
var staticRenderFns = []

export { render, staticRenderFns }