<template>
  <div class="d-flex align-center">
    <template v-if="rowData.type === 'select'">
      <v-select
        :value="rowData.value"
        @input="rowData.value = $event"
        :items="items"
        dense
        item-text="label"
        @change="$store.dispatch('SAVE_EDIT_FROM')"
        hide-details
        autofocus
        ref="selectBox"
        @keydown.native="closeSelectEditForm"
      >
        <template v-slot:selection="{ item }">
          <v-icon
            :color="
              item.value === 1
                ? 'red'
                : item.value === 2
                ? 'orange'
                : item.value === 3
                ? 'green'
                : ''
            "
            >mdi-circle-slice-8</v-icon
          >
        </template>
        <template v-slot:item="{ item }">
          <div class="d-flex justify-center align-center">
            <v-icon
              :color="
                item.value === 1
                  ? 'red'
                  : item.value === 2
                  ? 'orange'
                  : item.value === 3
                  ? 'green'
                  : ''
              "
              >mdi-circle-slice-8</v-icon
            >
          </div>
          {{ item.label }}
        </template>
      </v-select>
    </template>
    <template v-if="rowData.type === 'auto'">
      <v-autocomplete
        :value="rowData.value"
        @input="rowData.value = $event"
        :items="items"
        return-object
        item-text="name"
        dense
        hide-details
        @keydown.enter="$store.dispatch('SAVE_EDIT_FROM')"
        @keydown.esc="$store.dispatch('EDIT_FORM_CLOSE')"
        :filter="searchClientFilter"
      >
        <template v-slot:item="{ item }">
          {{ item.name }}
          {{ item.bill_client ? ` - ${item.bill_client.name}` : "" }}
        </template>
      </v-autocomplete>
    </template>
    <template v-if="rowData.type === 'text'">
      <v-text-field
        :value="rowData.value"
        @input="rowData.value = $event"
        autofocus
        dense
        hide-details
        @keypress.enter="$store.dispatch('SAVE_EDIT_FROM')"
        @keydown.esc="$store.dispatch('EDIT_FORM_CLOSE')"
      ></v-text-field>
    </template>
    <template v-if="rowData.type === 'numText'">
      <v-text-field
        :value="rowData.value"
        @input="rowData.value = $event"
        autofocus
        type="number"
        dense
        hide-details
        :prefix="prefix"
        :suffix="suffix"
        @keydown.enter="$store.dispatch('SAVE_EDIT_FROM')"
        @keydown.esc="$store.dispatch('EDIT_FORM_CLOSE')"
      ></v-text-field>
    </template>
    <template v-if="rowData.type === 'twoNumText'">
      <div class="unitGrid">
        <div>
          <v-text-field
            :value="rowData.value"
            @input="rowData.value = $event"
            autofocus
            type="number"
            dense
            hide-details
            :prefix="prefix"
            :suffix="suffix"
            @keydown.enter="$store.dispatch('SAVE_EDIT_FROM')"
            @keydown.esc="$store.dispatch('EDIT_FORM_CLOSE')"
          ></v-text-field>
        </div>
        <div class="d-flex align-center" style="align-self: end">|</div>
        <div>
          <v-text-field
            :value="rowData.value2"
            @input="rowData.value2 = $event"
            autofocus
            type="number"
            dense
            hide-details
            :prefix="'₩'"
            @keydown.enter="$store.dispatch('SAVE_EDIT_FROM')"
            @keydown.esc="$store.dispatch('EDIT_FORM_CLOSE')"
          ></v-text-field>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  props: ["items", "prefix", "suffix"],
  computed: {
    ...mapGetters({
      rowData: "getEditRowData",
    }),
  },
  components: {},
  methods: {
    remove(item) {
      const index = this.friends.indexOf(item.name);
      if (index >= 0) this.friends.splice(index, 1);
    },
    closeSelectEditForm(event) {
      if (event.keyCode === 27) this.$store.dispatch("EDIT_FORM_CLOSE");
    },
    searchClientFilter(item, queryText) {
      const searchTarget = `${item.name} ${
        item.bill_client ? ` - ${item.bill_client.name}` : ""
      }  `;

      return (
        searchTarget
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1 ||
        searchTarget
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },
  },
  mounted() {
    document.addEventListener("keydown", (e) => {
      this.closeSelectEditForm(e);
    });
  },
  beforeDestroy() {
    document.removeEventListener("keydown", (e) => {
      this.closeSelectEditForm(e);
    });
  },
};
</script>
<style>
.v-autocomplete.v-select.v-input--is-focused input {
  min-width: 0px !important;
}
</style>
