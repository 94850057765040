import VueContext from "vue-context";
import "vue-context/src/sass/vue-context.scss";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      includeModifyPage: [
        "order",
        "claim",
        "endClaim",
        "production",
        "purchasecalculate",
        "purchasedeadline",
        "salescalculate",
        "salesdeadline",
        "stock",
      ],
    };
  },
  components: {
    VueContext,
  },
  computed: {
    ...mapGetters({
      contextItem: "getContextData",
    }),
    contextParam() {
      return [
        { field: "pageMove" },
        { field: "order", params: [this.contextItem.item.order] },
      ];
    },
  },
  methods: {
    progressKind(kind) {
      return kind === 1
        ? "오더"
        : kind === 2
        ? "BT 의뢰"
        : kind === 3
        ? "BT 전달"
        : kind === 4
        ? "BT CMF"
        : kind === 5
        ? "MAIN 전달"
        : kind === 6
        ? "MAIN CMF"
        : kind === 7
        ? "가공의뢰서"
        : kind === 8
        ? "매입 출고의뢰서"
        : kind === 9
        ? "매출 출고의뢰서"
        : kind === 10
        ? "검사 의뢰서"
        : kind === 11
        ? "외관 검사서"
        : kind === 12
        ? "선매입"
        : kind === 13
        ? "이화학 의뢰"
        : kind === 14
        ? "이화학 결과"
        : kind === 15
        ? "매입/매출 출고의뢰서"
        : kind === 16
        ? "출고의뢰서"
        : kind === 17
        ? "재고 매입"
        : "";
    },

    movePage(route, mutationName) {
      if (this.contextItem.item) {
        this.$store.commit(mutationName, this.contextParam);
        this.$router.push({ name: route });
      }
    },

    orderPageMove() {
      if (this.contextItem.item) {
        this.$store.dispatch(
          "SET_FORM",
          JSON.parse(JSON.stringify(this.contextItem.order)),
        );
        this.movePage("order", "setOrderParams");
      }
    },

    async productionPageMove(item, index) {
      if (this.contextItem.item) {
        this.$store.dispatch("PRODUCTION_DIALOG_INIT");
        await this.$store.dispatch(
          "PRODUCTION_ORDER_CHANGE",
          this.contextItem.order,
        );

        if (item.id) await this.$store.dispatch("GET_ATTACHMENTLIST", item.id);

        if (this.requestDocCheck(item.kind)) {
          this.$store.commit("setProductionProgress", {
            ...item,
            index: index,
            isIndex: !index,
          });

          this.$store.dispatch("PRODUCTION_OPENDIALOG");
        } else {
          const progress = this.copyData(item);
          progress.index = index;
          this.$store.commit(
            "setProgressFileList",
            this.$store.state.attachmentList,
          );
          this.$store.commit("setProgressData", progress);
        }
        this.movePage("production", "setProductionParams");
      }
    },
    requestFormOpen(item) {
      const historyList = this.$store.state.apprvoalHistory;
      this.$store.dispatch("SET_REQUEST_FORM_DATA", {
        approvalId: item.approvalId,
        name: item.name,
        id: item.id,
        order: item.order,
      });
      this.$store.commit("setRequestApprovalList", historyList);
      this.$store.commit("setRequestFormDialog");
    },
  },
};
