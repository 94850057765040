<template>
  <v-dialog
    :value="dialog_edit"
    @click:outside="$store.state.isDialog = false"
    @keydown.esc="$store.state.isDialog = false"
    @keydown.enter="saveEditForm"
    max-width="600px"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ typeTitle[$store.state.dialogType] }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <!-- 거래처 변경 -->
              <template v-if="$store.state.dialogType === 'purchasePlace'">
                <v-autocomplete
                  v-model="$store.state.form.place_forward"
                  :items="$store.state.clientList"
                  return-object
                  item-text="name"
                  label="거래처"
                >
                  <template v-slot:item="{ item }">
                    {{ item.name }}
                    {{ item.bill_client ? ` - ${item.bill_client.name}` : "" }}
                  </template>
                  <template v-slot:selection="{ item }">
                    {{ item.name }}
                  </template>
                </v-autocomplete>
              </template>
              <!-- 매입 수량 변경 -->
              <template v-if="$store.state.dialogType === 'purchaseQuantity'">
                <v-text-field
                  v-model="$store.state.form.quantity_truth"
                  autofocus
                  type="number"
                  label="매입 수량"
                ></v-text-field>
              </template>
              <!-- 매입 수량 변경 -->
              <template v-if="$store.state.dialogType === 'eschamge'">
                <v-text-field
                  v-model="$store.state.form.eschamge"
                  autofocus
                  type="number"
                  label="매입 환율"
                ></v-text-field>
              </template>
              <!--비고 변경 -->
              <template
                v-if="
                  $store.state.dialogType === 'memo' ||
                  $store.state.dialogType === 'salesMemo'
                "
              >
                <v-textarea
                  v-model="$store.state.form.memo"
                  autofocus
                  rows="5"
                  label="메 모"
                ></v-textarea>
              </template>
              <!-- 매출처 변경 -->
              <template v-if="$store.state.dialogType === 'salesPlace'">
                <v-autocomplete
                  v-model="$store.state.form.place_sales"
                  :items="$store.state.clientList"
                  return-object
                  item-text="name"
                  label="매출처"
                >
                  <template v-slot:item="{ item }">
                    {{ item.name }}
                    {{ item.bill_client ? ` - ${item.bill_client.name}` : "" }}
                  </template>
                  <template v-slot:selection="{ item }">
                    {{ item.name }}
                  </template>
                </v-autocomplete>
              </template>
              <!-- 매출 수량 변경 -->
              <template v-if="$store.state.dialogType === 'salesQuantity'">
                <v-text-field
                  v-model="$store.state.form.sales_total_quantity"
                  autofocus
                  type="number"
                  label="매출 수량"
                ></v-text-field>
              </template>
              <template v-if="$store.state.dialogType === 'saleEschamge'">
                <v-text-field
                  v-model="$store.state.form.eschamge"
                  autofocus
                  type="number"
                  label="매출 수량"
                ></v-text-field>
              </template>
              <!-- 매출 단가 변경 -->
              <template v-if="$store.state.dialogType === 'salesUnitPrice'">
                <v-text-field
                  v-model="$store.state.form.unit_price_of_sales"
                  autofocus
                  label="단가"
                  :prefix="$store.state.form.currency_sign"
                ></v-text-field>
              </template>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="$store.state.isDialog = false"
        >
          취소
        </v-btn>
        <v-btn color="blue darken-1" text type="number" @click="saveEditForm">
          변경
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      typeTitle: {
        eschamge: "환율 변경",
        purchasePlace: "거래처 변경",
        purchaseQuantity: "매입 수량 변경",
        salesPlace: "매출처 변경",
        salesQuantity: "매출 수량 변경",
        salesUnitPrice: "매출 단가 수정",
        saleEschamge: "매출 단가 환율 변경",
        memo: "비고",
        salesMemo: "비고",
      },
    };
  },
  computed: {
    dialog_edit() {
      return this.$store.state.isDialog === true &&
        this.$store.state.isKind === "dialog_edit"
        ? true
        : false;
    },
  },
  methods: {
    saveEditForm() {
      const type = this.$store.state.dialogType;

      this.$store.dispatch("SET_API_LOADING", true);

      if (type === "saleEschamge") this.salesEschamgeCacul();
      if (type === "purchaseQuantity") this.purchaseColorListCacul();
      if (type === "eschamge") this.purEschamgeCacul();
      if (type === "purchasePlace") this.purchasePlaceChange();
      if (type === "memo") this.memoWrite();
      if (
        type === "salesQuantity" ||
        type === "salesUnitPrice" ||
        type === "salesPlace" ||
        type === "salesMemo"
      )
        this.$store.dispatch("TASK_SALESCAC_SAVE", "salesCac");
    },

    async purchasePlaceChange() {
      await this.$store.dispatch("SELECT_ORDER", this.$store.state.form);
      const release = this.$store.state.selectOrder.release.find(
        (x) => x.id === this.$store.state.form.id,
      );

      release.place_forward = this.$store.state.form.place_forward._id;

      this.saveData(this.$store.state.selectOrder);
      this.dialogOff();
    },

    async memoWrite() {
      const form = this.$store.state.form;
      await this.$store.dispatch("SELECT_ORDER", this.$store.state.form);
      const order = this.$store.state.selectOrder;
      const release = order.release.find((x) => x.id === form.id);

      if (this.$route.name === "salescalculate") release.sales.memo = form.memo;
      else release.purchase.memo = form.memo;

      this.saveData(this.$store.state.selectOrder);
      this.dialogOff();
    },

    async purchaseColorListCacul() {
      const form = this.$store.state.form;
      await this.$store.dispatch("SELECT_ORDER", this.$store.state.form);
      const order = this.$store.state.selectOrder;
      const release = order.release.find((x) => x.id === form.id);
      const manufacture = order.manufacture.find(
        (x) => x.id === release.manufacture,
      );
      const color = release.purchase.color_list[form.index];

      color.quantity = +form.quantity_truth;
      color.total_amount = Math.round(
        color.quantity * release.purchase.unit_price,
      );
      release.purchase.quantity = release.purchase.color_list.reduce(
        (r, c) => r + +(c.quantity ? c.quantity : 0).toFixed(2),
        0,
      );

      if (color.color_surcharge_unit) {
        color.color_surcharge_unit = Math.round(
          color.quantity *
            manufacture.color_list.find((x) => x.name === color.name)
              .color_surcharge_unit,
        );

        release.purchase.color_surcharge_price +=
          color.color_surcharge_unit -
          form.color_list[form.index].colorSurchargePrice;
      }

      if (color.surcharge_price) {
        color.surcharge_price = Math.round(
          color.quantity * manufacture.sucharge_price,
        );

        release.purchase.surcharge_price +=
          color.surcharge_price - form.color_list[form.index].surchargePrice;
      }

      release.purchase.total_amount = Math.round(
        release.purchase.quantity * release.purchase.unit_price +
          release.purchase.surcharge_price +
          release.purchase.color_surcharge_price,
      );

      if (release.purchase.eschamge)
        release.purchase.eschamge_price =
          release.purchase.total_amount * release.purchase.eschamge;

      await this.saveData(order);
      this.dialogOff();
    },

    async purEschamgeCacul() {
      await this.$store.dispatch("SELECT_ORDER", this.$store.state.form);
      const order = this.$store.state.selectOrder;
      const form = this.$store.state.form;
      const release = order.release.find((x) => x.id === form.id);

      release.purchase.eschamge = +form.eschamge;
      release.purchase.eschamge_price = Math.round(
        +form.eschamge * +form.totalAmount,
      );

      this.saveData(order);
      this.dialogOff();
    },

    async salesEschamgeCacul() {
      await this.$store.dispatch("SELECT_ORDER", this.$store.state.form);
      const order = this.$store.state.selectOrder;
      const form = this.$store.state.form;
      const release = order.release.find((x) => x.id === form.id);

      release.sales.eschamge = +form.eschamge;
      release.sales.eschamge_price = Math.round(
        +form.eschamge * +form.salesTotalAmount,
      );

      this.saveData(order);
      this.dialogOff();
    },

    async saveData(order) {
      this.$store.commit("setSaveData", order);
      await this.$store.dispatch("SAVE_ORDER");
      this.$store.commit("setSelectOrder");

      await this.$store.dispatch("GET_RELEASELIST", {
        isExisting: true,
        kind: this.$route.name,
      });
    },

    async dialogOff() {
      this.$store.dispatch("SET_API_LOADING", false);
      this.$store.commit("setDailogStatus", {
        status: false,
        kind: "dialog_edit",
      });
    },
  },
};
</script>
