<template>
  <v-data-table
    :headers="header"
    :items="pageList"
    :items-per-page="-1"
    :mobile-breakpoint="0"
    disable-sort
    hide-default-footer
    fixed-header
    class="printTable text-center"
    id="leftTable"
    item-key="name"
  >
    <!-- <template v-slot:[`body`]="{ items }">
      <tbody v-if="items.length">
        <tr v-for="(item, index) in items" :key="index">
          <template v-if="item.color_list.length">
            <td
              class="tableCellBorder_test claimPrice"
              v-if="
                header.value === 'salesQuantity' ||
                header.value === 'totalQuantity'
              "
            >
              {{
                (item[header.value] && item[header.value] !== "(비어있음)"
                  ? item[header.value] + item.unitSign
                  : "") | makeComma
              }}
            </td>
            <div
              class="tableCellBorder_test claimPrice"
              v-else-if="header.value === 'purchaseQuantity'"
            >
              {{
                (item[header.value] && item[header.value] !== "(비어있음)"
                  ? item[header.value] + item.purUnitSign
                  : "") | makeComma
              }}
            </div>
            <div
              class="tableCellBorder_test"
              v-else-if="header.value === 'month'"
            >
              <div>
                {{
                  item.carried
                    ? `${item[header.value]} (이월)`
                    : item[header.value]
                }}
              </div>
            </div>

            <div
              class="tableCellBorder_test"
              v-else-if="header.value === 'buyer'"
            >
              {{
                item[header.value] && item[header.value] !== "(비어있음)"
                  ? item[header.value]
                  : ""
              }}
            </div>

            <div
              v-else-if="
                item.currency_sign !== '₩' &&
                header.value === 'purchaseUnitPrice'
              "
              class="tableCellBorder_test claimPrice"
              @dblclick="
                $store.dispatch('EDIT_ROW', {
                  header: header,
                  items: item,
                  item: item.eschamge,
                  index: [index],
                  type: 'numText',
                })
              "
            >
              <template
                v-if="
                  editRowData.index[0] === index &&
                  editRowData.target === 'purchaseUnitPrice'
                "
              >
                <div class="text-left">
                  <div class="d-flex pl-1">
                    <div class="align-left">
                      {{ item[head.value] ? "₩" : "" }}
                    </div>
                    <div class="text-end" style="width: 100%">
                      {{
                        item.eschamgeUnit ? item.eschamgeUnit : "" | makeComma
                      }}
                    </div>
                  </div>
                  <div
                    style="font-size: 0.65rem; flex-grow: 1"
                    class="unitGrid text-right"
                  >
                    <div class="text-left pl-1 pt-2">
                      {{
                        item[head.value] && item[head.value] !== "(비어있음)"
                          ? (item.currency_sign ? item.currency_sign : "₩") +
                            item[head.value]
                          : ""
                      }}
                    </div>
                    <div class="text-right">|</div>
                    <div class="">
                      <editForm :prefix="'₩'"></editForm>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </template>
        </tr>
      </tbody>
    </template> -->
  </v-data-table>
  <!-- <v-container fluid>
    <div class="tableDisplay">
      <div class="tableHaeder" style="width: 100%">
        <div class="tableRowCell" style="width: 100%">
          <div
            class="tableCell text-center"
            v-for="col in header"
            :key="col.text"
            :style="{ width: col.width }"
          >
            <div
              v-if="col.value !== 'checkBox'"
              class="d-flex justify-center align-center"
              style="
                border-bottom: thin solid rgba(0, 0, 0, 0.12);
                height: 100%;
                background-color: #f5f5f5;
              "
            >
              <div
                :class="col.filterable !== false ? 'filterBodyText' : ''"
                @click="col.filterable !== false ? filterSet(col.value) : ''"
              >
                <span v-if="col.value === 'colorSurchargePrice'"
                  >염색 Surcharge</span
                >
                <span v-else-if="col.value === 'surchargePrice'"
                  >제직 Surcharge</span
                >

                <span v-else>{{ col.text }} </span>
                <v-menu
                  :value="isFilter && col.value === setFilter.name"
                  :close-on-content-click="false"
                  :nudge-width="200"
                  :close-on-click="true"
                  max-width="350px"
                  offset-y
                  transition="slide-y-transition"
                  right
                  fixed
                  style="position: absolute; right: 0"
                >
                  <v-list dense>
                    <v-list-item @click="sortItem('asc', col.value)">
                      <v-list-item-action>
                        <v-icon color="blue"
                          >mdi-sort-alphabetical-ascending</v-icon
                        >
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>오름차순 정렬</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="sortItem('desc', col.value)">
                      <v-list-item-action>
                        <v-icon color="blue"
                          >mdi-sort-alphabetical-descending</v-icon
                        >
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>내림차순 정렬</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <template v-if="col.type === 'number'">
                      <v-menu
                        offset-x
                        transition="slide-y-transition"
                        :close-on-content-click="false"
                        :nudge-width="200"
                        fixed
                        max-width="150px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-list-item v-on="on">
                            <v-list-item-icon />
                            <v-list-item-title>숫자 필터</v-list-item-title>
                            <v-list-item-action>
                              <v-icon>mdi-chevron-right</v-icon>
                            </v-list-item-action>
                          </v-list-item>
                        </template>
                        <v-list dense>
                          <v-list-item
                            @click="numDialogOpen(col.value, col.text, 'over')"
                            >보다 큼</v-list-item
                          >
                        </v-list>
                        <v-list dense>
                          <v-list-item
                            @click="numDialogOpen(col.value, col.text, 'low')"
                            >보다 작음</v-list-item
                          >
                        </v-list>
                        <v-list dense>
                          <v-list-item
                            @click="numDialogOpen(col.value, col.text, 'area')"
                            >해당 범위</v-list-item
                          >
                        </v-list>
                      </v-menu>
                    </template>
                    <template v-if="col.type === 'date'">
                      <v-menu
                        offset-x
                        transition="slide-y-transition"
                        :close-on-content-click="false"
                        :nudge-width="200"
                        fixed
                        max-width="150px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-list-item v-on="on">
                            <v-list-item-icon />
                            <v-list-item-title>날짜 필터</v-list-item-title>
                            <v-list-item-action>
                              <v-icon>mdi-chevron-right</v-icon>
                            </v-list-item-action>
                          </v-list-item>
                        </template>
                        <v-list
                          dense
                          v-for="month in monthList"
                          :key="month.value"
                        >
                          <v-list-item
                            @click="monthFilter(col.value, month.value)"
                            >{{ month.label }}</v-list-item
                          >
                        </v-list>
                      </v-menu>
                    </template>
                    <template> </template>

                    <v-list-item :disabled="!col.checked" @click="clearFilter">
                      <v-list-item-action
                        class="d-flex justify-end align-center"
                      >
                        <v-icon size="20" :color="col.checked ? 'red' : 'gray'"
                          >mdi-filter-remove</v-icon
                        >
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>필터해제</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="ml-4 mr-4" />
                  </v-list>

                  <v-list dense class="pa-2">
                    <div class="px-4">
                      <v-text-field
                        v-model="findKeyword"
                        dense
                        outlined
                        prepend-inner-icon="mdi-magnify"
                        label="검색"
                        @input="findFilter"
                        @keydown.enter="findKeywordFilter($event)"
                      ></v-text-field>
                    </div>

                    <template>
                      <v-list-item-group
                        multiple
                        active-class=""
                        style="height: 260px; width: 400px; overflow-y: overlay"
                      >
                        <template v-if="!findKeyword">
                          <div
                            v-for="(item, i) in setFilter.filter"
                            :key="item.name"
                            class="pl-4 d-table filterItem"
                          >
                            <div
                              v-if="i === 0"
                              @click="
                                () =>
                                  (setFilter.filter = mainAllCheck(
                                    setFilter.filter,
                                    !setFilter.filter[0].checked,
                                  ))
                              "
                              class="d-table"
                              style="width: 310px"
                            >
                              <input
                                type="checkbox"
                                :checked="item.checked"
                                style="transform: scale(1.1)"
                              />
                              <span
                                class="pl-3"
                                style="font-size: 14px; vertical-align: top"
                                >모두선택</span
                              >
                            </div>

                            <div
                              v-else
                              @click="mainFilterCheck(setFilter, item)"
                              class="d-table"
                              style="width: 300px"
                            >
                              <input
                                type="checkbox"
                                :checked="item.checked"
                                style="transform: scale(1.1)"
                              />
                              <span
                                v-if="col.type !== 'number'"
                                class="pl-2"
                                style="font-size: 14px; vertical-align: top"
                              >
                                {{ item.name }}
                              </span>
                              <span
                                v-else
                                class="pl-2"
                                style="font-size: 14px; vertical-align: top"
                              >
                                {{ item.name | makeComma }}
                              </span>
                            </div>
                          </div>
                        </template>
                        <template v-else>
                          <template v-if="findFilterList.length !== 0">
                            <div
                              v-for="(item, i) in findFilterList"
                              :key="item.name"
                              class="pl-4 d-table filterItem"
                            >
                              <div
                                v-if="i === 0"
                                @click="findAllCheck()"
                                class="d-table"
                                style="width: 310px"
                              >
                                <input
                                  type="checkbox"
                                  :checked="item.checked"
                                  class="pl-3"
                                  style="transform: scale(1.1)"
                                />
                                <span
                                  style="font-size: 14px; vertical-align: top"
                                  class="pl-2"
                                >
                                  (검색 결과 모두선택)
                                </span>
                              </div>
                              <div
                                v-else
                                @click="mainFilterCheck(setFilter, item)"
                                class="d-table"
                              >
                                <input
                                  type="checkbox"
                                  :checked="item.checked"
                                  style="transform: scale(1.1)"
                                />
                                <span
                                  v-if="col.type !== 'number'"
                                  class="pl-2"
                                  style="font-size: 14px; vertical-align: top"
                                >
                                  {{ item.name }}
                                </span>
                                <span
                                  v-else
                                  class="pl-2"
                                  style="font-size: 14px; vertical-align: top"
                                >
                                  {{ item.name | makeComma }}
                                </span>
                              </div>
                            </div>
                          </template>
                          <template v-else>
                            <div class="text-center">
                              검색 된 결과가 없습니다.
                            </div>
                          </template>
                        </template>
                      </v-list-item-group>
                    </template>
                    <v-divider class="ml-4 mr-4" />
                    <v-row class="mb-0 mt-1">
                      <v-col cols="1"></v-col>
                      <v-col cols="4" class="pb-0">
                        <v-btn
                          block
                          @click="
                            findKeyword
                              ? findKeywordFilter($event)
                              : applyFilter()
                          "
                          color="green darken-3"
                        >
                          <span class="white--text"> 적용 </span>
                        </v-btn>
                      </v-col>
                      <v-col cols="2"></v-col>
                      <v-col cols="4" class="pb-0">
                        <v-btn
                          block
                          color="orange darken-1"
                          @click="closeFilter"
                        >
                          <span class="white--text"> 닫기 </span>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-list>
                  <v-list dense> </v-list>
                </v-menu>
              </div>
            </div>
            <div
              v-else
              class="borderBottomPurchase d-flex justify-center align-center"
              style="height: 100%; background-color: #f5f5f5"
            >
              <span>
                <input
                  type="checkbox"
                  :checked="allCheckBox"
                  @click="checkBoxAllChange()"
                  class="v-checkbox"
                />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="flexTableBody" style="width: 100%">
        <template v-if="pageList.length">
          <div
            class="tableRowCell text-center"
            v-for="(item, index) in pageList"
            :key="index"
            style="width: 100%"
            @contextmenu.prevent="menuOpen($event, item)"
            :class="{ 'orange--text': item.status === '마감 요청' }"
          >
            <div
              v-for="head in header"
              :key="head.value"
              class="tableCellPurchase"
              :style="{ width: head.width }"
              @click="
                head.value !== 'purchaseQuantity'
                  ? $store.dispatch('EDIT_ROW_DATA_CHECK', {
                      index: [index],
                      item: item[head.value],
                      items: item,
                      header: head.value,
                    })
                  : ''
              "
            >
              <template v-if="item.color_list.length">
                <div
                  class="tableCellBorder_test claimPrice"
                  v-if="
                    head.value === 'salesQuantity' ||
                    head.value === 'totalQuantity'
                  "
                >
                  {{
                    (item[head.value] && item[head.value] !== "(비어있음)"
                      ? item[head.value] + item.unitSign
                      : "") | makeComma
                  }}
                </div>
                <div
                  class="tableCellBorder_test claimPrice"
                  v-else-if="head.value === 'purchaseQuantity'"
                >
                  {{
                    (item[head.value] && item[head.value] !== "(비어있음)"
                      ? item[head.value] + item.purUnitSign
                      : "") | makeComma
                  }}
                </div>
                <div
                  class="tableCellBorder_test"
                  v-else-if="head.value === 'month'"
                >
                  <div>
                    {{
                      item.carried
                        ? `${item[head.value]} (이월)`
                        : item[head.value]
                    }}
                  </div>
                </div>

                <div
                  class="tableCellBorder_test"
                  v-else-if="head.value === 'buyer'"
                >
                  {{
                    item[head.value] && item[head.value] !== "(비어있음)"
                      ? item[head.value]
                      : ""
                  }}
                </div>

                <div
                  v-else-if="
                    item.currency_sign !== '₩' &&
                    head.value === 'purchaseUnitPrice'
                  "
                  class="tableCellBorder_test claimPrice"
                  @dblclick="
                    $store.dispatch('EDIT_ROW', {
                      header: head,
                      items: item,
                      item: item.eschamge,
                      index: [index],
                      type: 'numText',
                    })
                  "
                >
                  <template
                    v-if="
                      editRowData.index[0] === index &&
                      editRowData.target === 'purchaseUnitPrice'
                    "
                  >
                    <div class="text-left">
                      <div class="d-flex pl-1">
                        <div class="align-left">
                          {{ item[head.value] ? "₩" : "" }}
                        </div>
                        <div class="text-end" style="width: 100%">
                          {{
                            item.eschamgeUnit
                              ? item.eschamgeUnit
                              : "" | makeComma
                          }}
                        </div>
                      </div>
                      <div
                        style="font-size: 0.65rem; flex-grow: 1"
                        class="unitGrid text-right"
                      >
                        <div class="text-left pl-1 pt-2">
                          {{
                            item[head.value] &&
                            item[head.value] !== "(비어있음)"
                              ? (item.currency_sign
                                  ? item.currency_sign
                                  : "₩") + item[head.value]
                              : ""
                          }}
                        </div>
                        <div class="text-right">|</div>
                        <div class="">
                          <editForm :prefix="'₩'"></editForm>
                        </div>
                      </div>
                    </div>
                  </template>

                  <template v-else>
                    <template v-if="item.eschamgeUnit">
                      <div class="d-flex pl-1">
                        <div class="align-left">
                          {{ item[head.value] ? "₩" : "" }}
                        </div>
                        <div class="text-end" style="width: 100%">
                          {{
                            item.eschamgeUnit
                              ? item.eschamgeUnit
                              : "" | makeComma
                          }}
                        </div>
                      </div>
                      <div
                        style="font-size: 0.65rem; flex-grow: 1"
                        class="unitGrid text-right"
                      >
                        <div class="text-left pl-1">
                          {{
                            item[head.value] &&
                            item[head.value] !== "(비어있음)"
                              ? (item.currency_sign
                                  ? item.currency_sign
                                  : "₩") + item[head.value]
                              : ""
                          }}
                        </div>
                        <div class="text-right">|</div>
                        <div class="">
                          {{
                            item.eschamge ? `₩${item.eschamge}` : "" | makeComma
                          }}
                        </div>
                      </div>
                    </template>
                    <template v-else>
                      <div class="d-flex">
                        <div class="pl-1 d-flex align-center">
                          {{
                            item.currency_sign &&
                            item[head.value] !== "(비어있음)"
                              ? item.currency_sign
                              : "₩"
                          }}
                        </div>
                        <div
                          class="claimPrice"
                          :class="item[head.value] < 0 ? 'minusPrice' : ''"
                        >
                          {{
                            (item[head.value] &&
                            item[head.value] !== "(비어있음)"
                              ? item[head.value]
                              : "") | makeComma
                          }}
                        </div>
                      </div>
                    </template>
                  </template>
                </div>

                <div
                  v-else-if="
                    !item.eschamge && head.value === 'purchaseUnitPrice'
                  "
                  class="tableCellBorder_test text-right"
                >
                  <template
                    v-if="
                      editRowData.index === index &&
                      editRowData.target === 'purchaseUnitPrice'
                    "
                  >
                    <editForm />
                  </template>
                  <template v-else>
                    <div class="d-flex">
                      <div class="pl-1 d-flex align-center">
                        {{
                          item.currency_sign &&
                          item[head.value] !== "(비어있음)"
                            ? item.currency_sign
                            : "₩"
                        }}
                      </div>
                      <div
                        class="claimPrice"
                        :class="item[head.value] < 0 ? 'minusPrice' : ''"
                      >
                        {{
                          (item[head.value] && item[head.value] !== "(비어있음)"
                            ? item[head.value]
                            : "") | makeComma
                        }}
                      </div>
                    </div>
                  </template>
                </div>

                <div
                  v-else-if="
                    item.eschamge_price && head.value === 'totalAmount'
                  "
                  class="tableCellBorder_test pt-1"
                >
                  <div class="pl-1 d-flex align-center">
                    <div class="">
                      {{ "₩" }}
                    </div>
                    <div
                      class="text-end claimPrice"
                      style="width: 100%"
                      :class="item.eschamge_price < 0 ? 'minusPrice' : ''"
                    >
                      {{
                        item.eschamge_price
                          ? `${item.eschamge_price}`
                          : "" | makeComma
                      }}
                    </div>
                  </div>
                  <div
                    class="pl-1 d-flex align-center"
                    style="font-size: 0.65rem; flex-grow: 1"
                  >
                    <div class="claimPrice">
                      {{
                        (item[head.value] && item[head.value] !== "(비어있음)"
                          ? `${item.currency_sign ? item.currency_sign : "₩"} ${
                              item[head.value]
                            }`
                          : "") | makeComma
                      }}
                    </div>
                  </div>
                </div>

                <div
                  v-else-if="head.value === 'totalAmount'"
                  class="tableCellBorder_test text-right"
                >
                  <div class="d-flex">
                    <div class="pl-1 d-flex align-center">
                      {{ item.currency_sign ? item.currency_sign : "₩" }}
                    </div>
                    <div
                      class="claimPrice"
                      :class="item[head.value] < 0 ? 'minusPrice' : ''"
                    >
                      {{
                        (item[head.value] && item[head.value] !== "(비어있음)"
                          ? item[head.value]
                          : "") | makeComma
                      }}
                    </div>
                  </div>
                </div>

                <div
                  class="tableCellBorder_test text-right"
                  v-else-if="
                    head.value === 'colorSurchargePrice' ||
                    head.value === 'surchargePrice' ||
                    head.value === 'amount'
                  "
                >
                  <div class="d-flex">
                    <div class="pl-1 d-flex align-center">
                      {{
                        item[head.value] && item[head.value] !== "(비어있음)"
                          ? item.currency_sign
                            ? item.currency_sign
                            : "₩"
                          : ""
                      }}
                    </div>
                    <div
                      class="claimPrice"
                      :class="item[head.value] < 0 ? 'minusPrice' : ''"
                    >
                      {{
                        (item[head.value] && item[head.value] !== "(비어있음)"
                          ? item[head.value]
                          : "") | makeComma
                      }}
                    </div>
                  </div>
                </div>

                <div
                  class="tableCellBorder_test text-center"
                  v-else-if="head.value === 'color'"
                >
                  {{
                    `${item[head.value]}${
                      item["color_list"]
                        ? item["color_list"].length > 1
                          ? ` (+ ${item["color_list"].length} )`
                          : ""
                        : ""
                    }`
                  }}
                </div>
                <div
                  class="tableCellBorder"
                  v-else-if="head.value === 'place_forward'"
                  @dblclick="
                    $store.dispatch('EDIT_ROW', {
                      header: head,
                      items: item,
                      item: item[head.value],
                      index: [index],
                      type: 'auto',
                    })
                  "
                >
                  <span
                    v-if="
                      editRowData.index[0] === index &&
                      editRowData.target === 'place_forward'
                    "
                  >
                    <editForm :items="$store.state.clientList"></editForm>
                  </span>
                  <span v-else>
                    {{
                      item[head.value] && item[head.value] !== "(비어있음)"
                        ? item[head.value]
                        : ""
                    }}</span
                  >
                </div>

                <div
                  class="memoBorder text-center"
                  v-else-if="head.value === 'memo'"
                  @dblclick="
                    $store.dispatch('EDIT_ROW', {
                      header: head,
                      items: item,
                      item: item[head.value],
                      index: [index],
                      type: 'text',
                    })
                  "
                >
                  <template
                    v-if="
                      editRowData.index[0] === index &&
                      editRowData.target === 'memo'
                    "
                  >
                    <editForm></editForm>
                  </template>
                  <template v-else>
                    <div class="">
                      <div
                        style="
                          white-space: nowrap;
                          text-overflow: ellipsis;
                          overflow: hidden;
                          max-width: calc(100vw - 94vw);
                        "
                      >
                        <CalculateTooltip :Tooltip="item[head.value]">
                        </CalculateTooltip>
                      </div>
                    </div>
                  </template>
                </div>

                <div
                  class="tableCellBorder_test"
                  v-else-if="head.value !== 'checkBox'"
                >
                  {{
                    item[head.value] && item[head.value] !== "(비어있음)"
                      ? item[head.value]
                      : ""
                  }}
                </div>

                <div
                  class="tableCellBorder d-flex justify-center align-center"
                  v-else
                >
                  <input
                    @click="checkboxChange(item)"
                    type="checkbox"
                    :checked="item.checkBox"
                    class="v-checkbox"
                  />
                </div>

                <span v-for="(color, i) in item.color_list" :key="i">
                  <div
                    class="tableCellBorder_test text-right"
                    v-if="head.value === 'purchaseQuantity'"
                    @dblclick="
                      $store.dispatch('EDIT_ROW', {
                        header: head,
                        items: item,
                        item: color[head.value],
                        index: [index, i],
                        type: 'numText',
                      })
                    "
                    @click="
                      $store.dispatch('EDIT_ROW_DATA_CHECK', {
                        index: [index, i],
                        item: color[head.value],
                        header: head.value,
                      })
                    "
                  >
                    <template
                      v-if="
                        editRowData.index[0] === index &&
                        editRowData.index[1] === i &&
                        editRowData.target === 'purchaseQuantity'
                      "
                    >
                      <editForm :suffix="item.purUnitSign"></editForm>
                    </template>
                    <template v-else>
                      <div class="d-flex justify-end pr-1 pl-1">
                        {{
                          color[head.value] &&
                          color[head.value] !== "(비어있음)"
                            ? color[head.value] + item.purUnitSign
                            : "" | makeComma
                        }}
                      </div>
                    </template>
                  </div>

                  <div
                    class="tableCellBorder_test claimPrice"
                    v-else-if="head.value === 'totalQuantity'"
                  >
                    {{
                      color[head.value] && color[head.value] !== "(비어있음)"
                        ? color[head.value] + item.unitSign
                        : "" | makeComma
                    }}
                  </div>

                  <div
                    class="tableCellBorder_test text-right"
                    v-else-if="head.value === 'salesQuantity'"
                  ></div>
                  <div
                    class="tableCellBorder_test text-right"
                    v-else-if="
                      head.value === 'purchaseUnitPrice' ||
                      head.value === 'amount' ||
                      head.value === 'colorSurchargePrice' ||
                      head.value === 'surchargePrice' ||
                      head.value === 'totalAmount'
                    "
                  >
                    <div class="d-flex">
                      <div class="pl-1 d-flex align-center">
                        {{
                          color[head.value] &&
                          color[head.value] !== "(비어있음)"
                            ? item.currency_sign
                              ? item.currency_sign
                              : "₩"
                            : ""
                        }}
                      </div>
                      <div
                        class="claimPrice"
                        :class="color[head.value] < 0 ? 'minusPrice' : ''"
                      >
                        {{
                          (color[head.value] &&
                          color[head.value] !== "(비어있음)"
                            ? decimalCacul(color[head.value])
                            : "") | makeComma
                        }}
                      </div>
                    </div>
                  </div>

                  <div v-else class="tableCellBorder_test">
                    {{
                      color[head.value] && color[head.value] !== "(비어있음)"
                        ? color[head.value]
                        : ""
                    }}
                  </div>
                </span>
              </template>

              <template v-else>
                <div
                  class="tableCellBorder_test text-right"
                  v-if="!item.eschamge_price && head.value === 'totalAmount'"
                >
                  <div class="d-flex">
                    <div class="pl-1 d-flex align-center">₩</div>
                    <div
                      class="claimPrice"
                      :class="item[head.value] < 0 ? 'minusPrice' : ''"
                    >
                      {{
                        (item[head.value] && item[head.value] !== "(비어있음)"
                          ? item[head.value]
                          : "") | makeComma
                      }}
                    </div>
                  </div>
                </div>
                <div
                  v-else-if="
                    item.eschamge_price && head.value === 'totalAmount'
                  "
                  class="tableCellBorder_test pt-1"
                >
                  <div class="pl-1 d-flex align-center">
                    <div class="">
                      {{ "₩" }}
                    </div>
                    <div
                      class="text-end claimPrice"
                      style="width: 100%"
                      :class="item.eschamge_price < 0 ? 'minusPrice' : ''"
                    >
                      {{
                        item.eschamge_price
                          ? `${item.eschamge_price}`
                          : "" | makeComma
                      }}
                    </div>
                  </div>
                  <div
                    class="pl-1 d-flex align-center"
                    style="font-size: 0.65rem; flex-grow: 1"
                  >
                    <div class="claimPrice">
                      {{
                        (item[head.value] && item[head.value] !== "(비어있음)"
                          ? `${item.currency_sign ? item.currency_sign : "₩"} ${
                              item[head.value]
                            }`
                          : "") | makeComma
                      }}
                    </div>
                  </div>
                </div>
                <div class="tableCellBorder" v-else-if="head.value === 'month'">
                  {{
                    item.carried
                      ? `${item[head.value]} (이월) `
                      : item[head.value]
                  }}
                </div>
                <div
                  class="tableCellBorder d-flex justify-center align-center"
                  v-else-if="head.value === 'checkBox'"
                >
                  <input
                    @click="checkboxChange(item)"
                    type="checkbox"
                    :checked="item.checkBox"
                    class="v-checkbox"
                  />
                </div>
                <div
                  class="memoBorder text-center"
                  v-else-if="head.value === 'memo'"
                  @dblclick="
                    $store.dispatch('EDIT_ROW', {
                      header: head,
                      items: item,
                      item: item[head.value],
                      index: [index],
                      type: 'text',
                    })
                  "
                >
                  <template
                    v-if="
                      editRowData.index[0] === index &&
                      editRowData.target === 'memo'
                    "
                  >
                    <editForm></editForm>
                  </template>
                  <template v-else>
                    <div class="">
                      <div
                        style="
                          white-space: nowrap;
                          text-overflow: ellipsis;
                          overflow: hidden;
                          max-width: calc(100vw - 94vw);
                        "
                      >
                        <CalculateTooltip
                          :Tooltip="item[head.value]"
                        ></CalculateTooltip>
                      </div>
                    </div>
                  </template>
                </div>
                <div
                  class="tableCellBorder"
                  v-else-if="head.value === 'place_forward'"
                >
                  <template
                    v-if="
                      editRowData.index[0] === index &&
                      editRowData.target === 'place_forward'
                    "
                  >
                    <editForm :items="$store.state.clientList"></editForm>
                  </template>
                  <template v-else>
                    <span> {{ item[head.value] }}</span>
                  </template>
                </div>
                <div v-else class="tableCellBorder_test">
                  {{
                    item[head.value] && item[head.value] !== "(비어있음)"
                      ? item[head.value]
                      : ""
                  }}
                </div>
              </template>
            </div>
          </div>
        </template>
        <template v-else>
          <div
            class="tableRowCell text-center"
            v-for="(item, i) in 11"
            :key="i"
            style="width: 100%"
          >
            <div
              v-for="head in header"
              :key="head.value"
              class="tableCell"
              :style="{ width: head.width }"
            >
              &nbsp;
            </div>
          </div>
        </template>
      </div>
    </div>
  </v-container> -->
</template>
<script>
import { mapGetters } from "vuex";
import header from "@/assets/table/purchase/purchaseCalculateHeaders";

export default {
  components: {},

  computed: {
    ...mapGetters({
      originalList: "getPurchCalculOrigin",
      pageList: "getPurchCalculList",
      filterList: "getPurchCalculFilter",
      allCheckBox: "getPurCheckBox",
      editRowData: "getEditRowData",
      monthList: "getMonthList",
    }),
  },

  data() {
    return {
      header,
      headerList: {},
      searchValue: "",
      findFilterList: [],
      setFilter: {},
      findKeyword: "",
      isFilter: false,
      firSetFilter: {
        filter: [],
        name: "",
      },
      menu_date_register: [],
    };
  },

  methods: {
    filterSet(data) {
      this.findKeyword = "";
      this.isFilter = this.setIsFilter(this.setFilter.name, data);
      this.setFilter = this.isFilter
        ? this.setFilterSet(
            this.filterList[data],
            this.header.findIndex((x) => x.value === data),
            this.setFilter,
            data,
          )
        : {};
    },

    // 키워드 검색 후 특정 적용 버튼 클릭시
    findKeywordFilter(e) {
      if (!this.findFilterList.length) return;
      e.type === "click"
        ? this.mainKeyWordFilterSet(this.findFilterList, this.setFilter)
        : this.mainEnterFiter(this.findFilterList, this.setFilter);
      this.applyFilter();
    },

    applyFilter() {
      this.mainApplyFilter(
        this.setFilter,
        this.header,
        this.filterList,
        this.firSetFilter,
      );
      this.filterLing();
      this.closeFilter();
    },

    clearFilter() {
      const index = this.setFilter.hederIndex;

      if (this.setFilter.name === this.firSetFilter.name) {
        this.firSetFilter.filter = [];
        this.firSetFilter.name = "";
        this.header[index].first = false;
      }

      this.header.forEach((x) => {
        if (!x.checked || this.setFilter.name === x.value) {
          this.filterList[x.value] = this.copyData(
            this.$store.state.originFilter[x.value],
          );
        }
      });

      this.header.filter((x) => x.checked).length === 1
        ? this.$store.commit(
            "setPurchCalculList",
            this.copyData(this.originalList),
          )
        : this.filterLing();

      this.header[index].checked = false;

      this.clearSetFilter();
      this.setFilterList();
    },

    closeFilter() {
      this.setFilterList();
      this.clearSetFilter();
    },

    clearSetFilter() {
      this.isFilter = false;
      this.findFilterList = [];
      this.setFilter = {};
      this.findKeyword = "";
    },

    findAllCheck() {
      this.findFilterList = this.mainAllCheck(
        this.findFilterList,
        !this.findFilterList[0].checked,
      );
      this.findFilterList.forEach((x) => {
        const index = this.setFilter.filter.findIndex((y) => y.name === x.name);
        this.setFilter.filter[index] = x;
      });
    },

    findFilter() {
      if (!this.setFilter.name) return;
      this.findFilterList = this.mainFindFilter(
        this.setFilter,
        this.header,
        this.findKeyword,
      );
    },

    filterLing() {
      this.$store.commit(
        "setPurchCalculList",
        this.mainFilterLing(
          this.filterList,
          this.copyData(this.originalList),
          this.setFilter,
          this.header,
          this.firSetFilter.name,
        ),
      );
    },

    setFilterList() {
      this.mainSetFilterList(
        this.header,
        this.$store.state.originFilter,
        this.pageList,
        this.filterList,
        this.firSetFilter.name,
        this.setFilter.name,
      );
    },

    sortItem(stat, header) {
      this.mainSortItem(stat, header, this.pageList);
      this.closeFilter();
    },

    monthFilter(header, value) {
      this.setFilter.filter = this.mainAllCheck(this.setFilter.filter, false);
      const monthFilterData = this.copyData(this.originalList).filter((x) => {
        if (x[header]) return x[header].split("-")[0] === value;
      });
      if (monthFilterData) {
        this.mainMonthFilter(monthFilterData, this.setFilter);
        this.applyFilter();
      }
    },

    numFiltering() {
      if (this.$store.state.numFilteringList && this.setFilter.filter) {
        this.mainNumFilter(
          this.$store.state.numFilteringList,
          this.$store.state.numDialogData.headerVal,
          this.setFilter,
        );
        this.applyFilter();
      } else return;
    },

    numDialogOpen(value, text, type) {
      this.$store.dispatch("NUM_DIALOG_OPEN", {
        headerVal: value,
        headerText: text,
        type: type,
        target: "salesRelease",
      });
    },

    createdFilterSet() {
      const firstHeader = this.header.find((x) => x.first);
      if (firstHeader)
        this.mainFirstFilter(
          this.firSetFilter,
          firstHeader.value,
          this.filterList[firstHeader.value],
        );
    },

    ///////////////////////////////////////////////////////////////////////////

    // checkbox 전체 선택
    checkBoxAllChange() {
      this.$store.commit("setPurCheckBox", !this.allCheckBox);
      this.pageList.forEach((x) => {
        x.checkBox = this.allCheckBox;
      });
    },

    checkboxChange(release) {
      const originRelease = this.originalList.find((x) => x.id === release.id);
      release.checkBox = !release.checkBox;
      if (!release.checkBox) this.$store.commit("setPurCheckBox", false);
      else {
        let count = 0;
        this.pageList.forEach((x) => {
          if (x.checkBox) count++;
        });
        if (count === this.pageList.length)
          this.$store.commit("setPurCheckBox", true);
      }
      originRelease.checkBox = release.checkBox;
    },

    async modifyMonth(item, index) {
      this.$store.dispatch("SET_API_LOADING", true);
      await this.$store.dispatch("SELECT_ORDER", item);

      const release = this.$store.state.selectOrder.release.find(
        (x) => x.id === item.id,
      );
      release.purchase.date = new Date(`${item.modifyDate}-02`);

      this.$store.commit("setSaveData", this.$store.state.selectOrder);
      await this.$store.dispatch("SAVE_ORDER");
      this.$store.commit("setSelectOrder");
      await this.$store.dispatch("GET_RELEASELIST", {
        kind: this.$route.name,
        isExisting: true,
      });
      this.$store.dispatch("SET_API_LOADING", false);
      this.$refs[`menu_date_register${index}`][0].isActive = false;
    },

    menuOpen(event, item) {
      this.$store.dispatch("CONTEXT_MENU_DATA_PARSE", { item: item });
      this.$refs.menu.$children[0].open(event);
    },
  },

  async created() {
    this.$store.dispatch("SET_API_LOADING", true);
    await this.$store.dispatch("GET_RELEASELIST", {
      kind: "purchasecalculate",
    });
    this.$store.commit("setNumFilterList", this.originalList);
    this.createdFilterSet();
    this.$store.dispatch("SET_API_LOADING", false);
  },

  mounted() {
    document.addEventListener("keydown", (e) => {
      if (e.keyCode == 27 && this.isFilter) {
        this.closeFilter();
      }
    });
    this.$store.watch(
      () => this.$store.state.numFilteringList,
      () => {
        this.numFiltering();
      },
    );
  },
};
</script>
