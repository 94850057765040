export default {
  ["취"]: "10",
  ["취소 요청"]: "10",
  ["취소"]: "10",
  ["승인 요청"]: "10",
  ["요청"]: "20",
  ["승인"]: "40",
  ["마"]: "00",
  ["마감"]: "00",
};
